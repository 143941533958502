import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IProfileLinks } from '../models/profile-links';
import { IProduct } from '../models/product';
import { OAuthService } from 'angular-oauth2-oidc';
import { USER_PROFILE_FILTER_ARGS, ISO_EDUCATION_FILTER_ARGS, PRIMARY_HOST_DOMAIN } from '../shared/constants/app.constants';
import { LoginService } from '../services/login.service';
import { ProductService } from '../services/product.service';
import { ConfigService } from '../services/config.service';
import { AdminService } from 'src/app/services/admin.service';
import { ScriptService } from '../services/script.service';
import { ProfileSelectionService } from '../shared/services/profile-selection.service';

const CUSTOMER_NAME = 'customername';
declare function loadCustomerSelectionJS(): any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  objectKeys = Object.keys;

  userProfileLinks: IProfileLinks[];  // user drop-down menu data set
  userISOProducts: IProduct[]; // megamenu data set
  allISOProducts: IProduct[];  // megamenu data set
  categoryISOProducts: any; // megamenu data set
  scriptPath = '';
  userProfileFilterArgs = USER_PROFILE_FILTER_ARGS;
  isoEducationFilterArgs = ISO_EDUCATION_FILTER_ARGS;

  errorMessage: any = '';
  hasAllProducts = '';
  expandAllProductsDisabled = '';
  dropdownClass = '';
  megaMenuApiUrl = '';
  redirectUrl = '';
  userGuideUrl: string = '';
  homePageApiUrl: string = '';
  adminPageApiUrl: string = '';

  customerSupportUrl = '';
  isAuthenticated = false;
  hoverMegamenu = 'allprod';
  adminUserId: string = '';
  loginUserId: string = '';
  isShow: boolean = false;
  customerName = '';
  switchProfileEnabled: boolean = false;

  constructor(private productService: ProductService,
    private cookieService: CookieService,
    private configService: ConfigService,
    private oauthService: OAuthService,
    private loginService: LoginService,
    private adminService: AdminService,
    private scriptService: ScriptService,
    private renderer: Renderer2,
    private profileSelectionService: ProfileSelectionService) {

    this.allISOProducts = [];
    this.categoryISOProducts = {};

    const userId: string = this.cookieService.get('userid');
    // const userId = 'aaauser';
    // const ticket = 'ECFBEAE1XBC71X41A8XBEADXC6FAABFA2ACA';
    const ticket: string = this.cookieService.get('ticket');
    const appId = this.cookieService.get('prd_ctx') ? this.cookieService.get('prd_ctx') : window.location.hostname;
    this.loginUserId = userId;
    

    this.configService.getConfigStatus().subscribe(
      configStatus => {
        if (configStatus) {
          const appConfig = this.configService.getGlobalConfig();
          this.megaMenuApiUrl = appConfig.megaMenuApiUrl;
          this.redirectUrl = appConfig.redirectUrl;
          this.userGuideUrl = appConfig.userGuideUrl;
          this.homePageApiUrl = appConfig.homePageApiUrl;
          this.adminPageApiUrl = appConfig.adminApiUrl;

          this.customerSupportUrl = appConfig.veriskCustomerSupport;

          this.adminService.getUserEligibility(this.adminPageApiUrl, ticket).subscribe(
            data => {
              this.loginService.setEligibilityStatus(data);
            },
            error => {
              console.log(error);
            });

            this.profileSelectionService.getAllCompanyAccess(appConfig.userProfileApiUrl, ticket, appConfig.prdCtx)
              .subscribe(
                data => {
                  if (data && data.length > 1) {
                    this.switchProfileEnabled = true;
                    this.scriptPath = appConfig.customerSelectionScript
                    const scriptElement = this.scriptService.loadJsScript(this.renderer, this.scriptPath);
                    
                    scriptElement.onload = () => {
                      loadCustomerSelectionJS();
                      this.scriptService.setCustomerNameObs();
                      }
                      scriptElement.onerror = () => {
                        console.log('Could not load the customer selection script!');
                      }
                  }
                  else{
                    this.switchProfileEnabled = false;
                  }
                },
                error => {
                  this.errorMessage = error;
                }
              );

          this.adminService.getAdminUser(this.adminPageApiUrl).subscribe(
            data => {
              this.adminUserId = data;
              localStorage.setItem('admin', this.adminUserId);
              if (this.adminUserId == this.loginUserId) {
                this.isShow = true;
              }
            },
            error => {
              console.log(error);
            });

          this.productService.getAllProducts(this.megaMenuApiUrl).subscribe(
            data => {
              // populate category products
              data['categories'].forEach(c => {
                this.categoryISOProducts[c['title']] = c['products'];
              });
              // populate all products
              let url = "";
              data['products'].forEach(p => {
                url = p['url'] ? p['url'].trim() : "";
                const item: IProduct = {
                  id: p['id'],
                  title: p['title'],
                  url: url,
                  isEligible: p['isEligible'],
                  appCtxt: "undefined"
                };
                this.allISOProducts.push(item);
              });

              if (ticket && userId) {
                this.productService.getEntitledUserProfileLinks(userId, ticket, this.megaMenuApiUrl).subscribe(
                  userProfileLinks => {
                    // decorate received url for iso education and append ticket and userid to the url
                    userProfileLinks.forEach(up => {
                      if (up.linkFor === 'ISO Education') {
                        up.url += '?ticket=' + ticket + '|' + userId;
                      }
                    });

                    this.userProfileLinks = userProfileLinks;
                  },
                  error => this.errorMessage = error
                );

                this.productService.getUserProducts(userId, ticket, this.megaMenuApiUrl,appId).subscribe(
                  userProducts => {
                    // populate user products
                    this.userISOProducts = userProducts;
                    this.hoverMegamenu = 'myprod';
                    userProducts.forEach(p => {
                      // reset eligible products in all products
                      const index = this.allISOProducts.findIndex((element) => {
                        return element.title === p.title;
                      });
                      if (index > -1) {
                        this.allISOProducts[index].isEligible = true;
                        this.allISOProducts[index].appCtxt = p.appCtxt;
                      }
                      // reset eligible products in category products
                      // tslint:disable-next-line: forin
                      for (const key in this.categoryISOProducts) {
                        const i = this.categoryISOProducts[key].findIndex((element) => {
                          return element.title === p.title;
                        });
                        if (i > -1) {
                          this.categoryISOProducts[key][i].isEligible = true;
                        }
                      }
                    });
                  },
                  error => this.errorMessage = error
                );
              }
            }
          );
        }
      },
      error => this.errorMessage = error
    );
  }

  ngOnInit(): void {
    this.loginService.getLoginStatus().subscribe(
      status => this.isAuthenticated = status
    );
    this.customerName = localStorage.getItem("customername");
  

    //this.adminService.getAdminUser('http://localhost:60418/api/Admin').subscribe(
    // this.adminService.getAdminUser(this.adminPageApiUrl).subscribe(
    //   data => {
    //       this.adminUserId = data;
    //       localStorage.setItem('admin', this.adminUserId);
    //       if (this.adminUserId == this.loginUserId)
    //       {
    //       this.isShow = true;
    //       }
    //   },
    //   error => {
    //       console.log(error);
    //   });

  }

  ngOnDestroy(): void {
    this.scriptService.removeScript(this.renderer, this.scriptPath);
  }

  public login(): void {
    this.oauthService.initImplicitFlow();
  }

  public signout(): void {
    this.cookieService.deleteAll('/', PRIMARY_HOST_DOMAIN);
    this.loginService.setLoginStatus(false);
    this.oauthService.logOut();
  }

  hoverIn(category: string): void {
    this.hoverMegamenu = category;
  }
  hrefOpen(url){
    window.open(url, "_blank");
  }
}
